html, body{
	margin: 0;
	padding: 0 !important;
	background-color: #ccc;
	overflow: visible !important;
	font-family: 'vazir';
}
@font-face {
    font-family: Vazir;
    src: url('../fonts/Vazir/Vazir-Regular.eot');
    src: url('../fonts/Vazir/Vazir-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Vazir/Vazir-Regular.woff2') format('woff2'),
         url('../fonts/Vazir/Vazir-Regular.woff') format('woff'),
         url('../fonts/Vazir/Vazir-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: Vazir;
    src: url('../fonts/Vazir/Vazir-Bold.eot');
    src: url('../fonts/Vazir/Vazir-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Vazir/Vazir-Bold.woff2') format('woff2'),
         url('../fonts/Vazir/Vazir-Bold.woff') format('woff'),
         url('../fonts/Vazir/Vazir-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: Vazir;
    src: url('../fonts/Vazir/Vazir-Black.eot');
    src: url('../fonts/Vazir/Vazir-Black.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Vazir/Vazir-Black.woff2') format('woff2'),
         url('../fonts/Vazir/Vazir-Black.woff') format('woff'),
         url('../fonts/Vazir/Vazir-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: Vazir;
    src: url('../fonts/Vazir/Vazir-Medium.eot');
    src: url('../fonts/Vazir/Vazir-Medium.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Vazir/Vazir-Medium.woff2') format('woff2'),
         url('../fonts/Vazir/Vazir-Medium.woff') format('woff'),
         url('../fonts/Vazir/Vazir-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: Vazir;
    src: url('../fonts/Vazir/Vazir-Light.eot');
    src: url('../fonts/Vazir/Vazir-Light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Vazir/Vazir-Light.woff2') format('woff2'),
         url('../fonts/Vazir/Vazir-Light.woff') format('woff'),
         url('../fonts/Vazir/Vazir-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: Vazir;
    src: url('../fonts/Vazir/Vazir-Thin.eot');
    src: url('../fonts/Vazir/Vazir-Thin.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Vazir/Vazir-Thin.woff2') format('woff2'),
         url('../fonts/Vazir/Vazir-Thin.woff') format('woff'),
         url('../fonts/Vazir/Vazir-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Noon';
    src: url(../fonts/Noon/Noon-Regular.woff2) format('woff2'),
         url(../fonts/Noon/Noon-Regular.woff) format('woff'),
         url(../fonts/Noon/Noon-Regular.ttf) format('truetype');
}


@font-face{
    font-family: "Neyrizi";
    src: url('../fonts/Neyrizi/Neirizi.ttf');
}

@font-face{
    font-family: "Quran Taha";
    src: url('../fonts/Quran-Taha/QuranTaha.ttf');
}

@font-face{
    font-family: "Kitab";
    src: url('../fonts/Kitab/Kitab-Regular.ttf');
}

:root{
	--primary-light:#008080;
	--primary-light-light:#7BC5BE;
	--primary-dark:#008080;
	--white:#fff;
	--black:#000;
}

/******************************************************************************/
/************************************* *****************************************/
/************************************* *****************************************/
/************************************   ****************************************/
/******************************              ***********************************/
/********************                                 **************************/
/*************                                                 *****************/
/********                                                              *********/
/*****                                                                      ****/
/**                                                                           **/
/*                                                                             */
#appbar{
	position: fixed;
	top: 0;
	z-index: 2;
	height:8vh;
	box-shadow: none;
	justify-content: space-between;
	padding-right: 0 !important;
}

.appbar_rtl{flex-direction: row !important;}
.appbar_ltr{flex-direction: row-reverse !important;}
.appbar_light{background-color: var(--primary-light) !important;}
.appbar_dark{background-color: var(--primary-dark) !important;}
/*applied to div that contains buttons at appbar*/
#appbarIcons , #appbarMenuTitle,#quranPageHeaderIcons{
	display: flex;
	padding-right: 1vw;
	margin: 1vh;
	font-family: vazir;
}
.appbarIcon,.quranHeaderIcon,.recitebarIcon{
	padding:0 !important;
	height:6vh;
	width:6vh;
}
.appbarIcons_ltr,.quranPageHeaderIcons_ltr,.appbarMenuTitle_ltr{flex-direction: row;}
.appbarIcons_rtl,.quranPageHeaderIcons_rtl,.appbarMenuTitle_rtl{flex-direction: row-reverse;}
.appbarIcon_light,.quranHeaderIcon_dark,.recitebarIcon_dark{color:var(--white) !important;}
.appbarIcon_dark,.quranHeaderIcon_light,.recitebarIcon_light{color:var(--primary-light) !important;}
.quranHeaderIcon_disable{color:var(--primary-light-light) !important;}
#drawerTitle,.tooltip{font-family: vazir !important;}
.drawerTitle_rtl{direction: rtl;}
.drawerTitle_ltr{direction: ltr;}
.drawerItem{
	padding: 10px 0px 10px 0px !important;
	margin-left: 13%;
	margin-right: 13%;
	width: 74%;
}
.drawerItemError{border: 1px solid red !important;}
#autocompelete-sidebar{font-family: vazir !important;}
.autocompelete-textfield > div::before{border-bottom: 1px solid rgba(0, 0, 0, 0.07) !important;}
.listItem_rtl {
	direction: rtl;
	text-align: right !important;
	font-family: vazir !important;
}
.listItem_ltr{
	direction: ltr;
	font-family: vazir !important;
}
.listItemIcon_light{color:var(--primary-light) !important;}
.listItemIcon_dark{color:var(--primary-dark) !important;}
.drawerItemLabel{
	padding: 6px 6px 0px 6px !important;
	margin-left: 13%;
	margin-right: 13%;
	font-family: vazir !important;
	width: 74%;
}
.drawerItem_rtl,.drawerItemLabel_rtl{direction: rtl;}
img.flag{
	width: 5vh;
	height: 5vh;
}
/*search drawer has a 100vw backdrop fill*/
#searchDrawer .MuiBackdrop-root{width: 100Vw;}
#searchTopBar{
	position: fixed !important;
	top: 0;
	z-index: 2;
	padding: 0 !important;
	height: 8vh;
	box-shadow: none !important;
}
.searchTopBar_light{background-color: var(--primary-light);}
.searchTopBar_dark{background-color: var(--primary-dark);}
.searchTopBar_rtl,.loginTopBar_rtl{
	flex-direction: row-reverse;
	left:0 !important;
}
.searchTopBar_ltr,.loginTopBar_ltr{
	flex-direction: row;
	right:0 !important;
}
#searchContent{
	position: fixed;
	top: 8vh !important;
	z-index: 2;
	box-sizing: border-box;
	min-height: 170px;
	box-shadow: none !important;
	padding-bottom: 4vh;
}
.searchContent_light{
	background-color: var(--primary-light) !important;
	color: var(--white);
}
.searchContent_dark{
	background-color: var(--primary-dark) !important;
	color: var(--white);
}
.searchContent_rtl{
	flex-direction: row-reverse;
	left:0 !important;
}
.searchContent_ltr{
	flex-direction: row;
	right:0 !important;
}
.searchBox{
	width: 100%;
	box-sizing: border-box;
	padding: 0 10px;
	height: 5vh;
	line-height: 43px;
	font-family: vazir;
	font-size: 14px;
	border-radius: 0;
	border: none;
	margin: 0 3vw;
}
.searchBox_ltr{direction: ltr;}
.searchBox_rtl{direction: rtl;}

/*tab indicator*/
.searchContent_light span[class^="PrivateTabIndicator"]{background-color: var(--primary-light);}
.drawerCloseIcon_light{color: var(--white) !important;}
.drawerCloseIcon_dark{color: var(--primary-light) !important;}
#searchTitle_light{color: var(--white);}
#searchTitle_dark{color: var(--white);}
#advanceSearchButton{
	position: absolute !important;
	top:0;
	bottom: 0;
	margin: 3px !important;
	box-shadow: none !important;
}
.advanceSearchButton_ltr{right: 3vw;}
.advanceSearchButton_rtl{left: 3vw;}
#advancedSearchContainer{
	margin: 2vw;
	border-radius:0;
	padding: 2vw;
}
.textField{margin-bottom: 1vw !important;}
.textField_ltr{direction: ltr;}
.textField_ltr>label{
	left: 0;
	right: auto;
}
.textField_rtl{direction: rtl;}
.textField_rtl>label{
	left: auto;
	right: 0;
}
.searchButton{
	position: absolute!important;
	top: 95% !important;
}
.searchButton_ltr{right: 40px !important}
.searchButton_rtl{left: 40px!important;}
.tabs_ltr{direction:ltr !important;}
.tabs_rtl{direction:rtl !important;}
#search_result{
	padding: 30px 0px !important;
	position: absolute;
	top: 224px;
	width: 100%;
	height: calc(100% - 227px);
	overflow: auto;
	box-sizing: border-box;
}
#logo{
	display: inline !important;
	vertical-align: super !important;
	font-family: 'vazir' !important;
	font-weight: bold !important;
	line-height: 6vh !important;
}
.logo_light{color:var(--white)}
.optionsTab{
	font-weight: bold !important;
	font-size: 1.2rem !important;
}
.optionsDisplayInside{padding: 0 10px 10px 10px;}
#optionsDisplayTabContent{
	grid-template-columns: 50% 50%;
	overflow: auto;
}
#optionsTajwidTabContent{
	grid-template-columns: auto;
	padding-top: 30px;
	overflow: overlay;
}
.optionsInput{
	margin-left: 13% !important;
	margin-right: 13% !important;
	font-family: vazir !important;
	width: 74%;
}
.optionsInputLabel{
	line-height: 20px !important;
	font-size: 1.2rem !important;
	font-family: vazir !important;
	height: 20px !important;
	margin-top: 50px;
}
.optionsSwitch{
	line-height: 48px !important;
	font-size: 1.2rem !important;
}
.optionsSlider{
	padding:24px 0px !important;
}
.optionsAppereanceTitle_rtl{
	float: right;
	background-color: var(--white) !important;
	width: 100%;
}
.optionsAppereanceTitle_ltr{
	float: left;
	background-color: var(--white) !important;
	width: 100%;
}
.optionsInputLable_rtl,.optionsSwitch_rtl,.optionsSlider_rtl{float: right;}
.optionsInputLable_ltr,.optionsSwitch_ltr,.optionsSlider_ltr{float: left;}
#wrapper{
	padding-top: 8vh;
	background-repeat: repeat-x;
	background-position: top;
}
.wrapper_cd {width: 100%;}
#quranPageHeader{
	position: fixed !important;
	top: 8vh !important;
	z-index: 1;
	height: 8vh;
	box-shadow: none !important;
	justify-content: space-between;
	padding: 0 1vh !important;
	border: 1px solid ;
}
.quranPageHeader_rtl{flex-direction: row !important;}
.quranPageHeader_ltr{flex-direction: row-reverse !important;}
.quranPageHeader_light{background-color: var(--white) !important;}
.quranPageHeader_dark{background-color: var(--primary-dark) !important;}
#quranPageHeaderTitle{
	height: 8vh !important;
	padding: 0 4vw !important;
	font-family: vazir !important;
	line-height: 8vh !important;
}
#content{
	position: relative;
	min-height: 100vh;
	direction: rtl;
	box-sizing:border-box;
	padding: 8vh 4vw;
}
.content_light{background-color: var(--white);}
.content_dark{background-color: var(--black);}
.translate{
	font-family: 'vazir';
	font-size: 14px;
	font-weight: normal;
	text-align: justify !important;
}
.translate_rtl{direction: rtl;}
.translate_lrt{direction: ltr;}
#recitebarHolder{
	position: sticky;
	bottom: 0;
	height: 8vh;
	z-index: 1;
	box-shadow: none;
	padding-right: 0 !important;
}
.recitebarHolder_light{background-color: var(--white) !important;}
.recitebarHolder_dark{background-color: var(--white) !important;}
#recitebar{
	position: absolute;
	right:0;
	left:0;
	height: 8vh;
	top:0;
	text-align: center;
	box-sizing: border-box;
	overflow: hidden;
	transition: 0.1s left;
	z-index: 1;
	margin: 0 auto;
}
#recitebarPageNav{
	display: inline;
	float: right;
	text-align: right;
	padding-right: 15px;
	box-sizing: border-box;
	height: 100%;
}
#recitebarPlayerControls{
	display: inline;
	float: left;
	padding-left: 15px;
	text-align: left;
	box-sizing: border-box;
	height: 100%;
}
footer{
	bottom: 0;
	font-family: 'Roboto';
	height: 56px;
	line-height: 64px;
	box-sizing:border-box;
	text-align: center;
	z-index: 2;
}
#drawerlogo{width: 20vh;align-self: center;padding: 0 0 2vh 0;}
#loginDrawer .MuiBackdrop-root{background-color: #00000000;}
#loginTopBar{height: 8vh;min-height: 8vh;}
#loginContainer{
	height: 100%;
	position: relative;
	padding:0;
	background-color: white;
	border-radius:25px 25px 0 0;
	border: 1px solid white;
}
#loginVerification , #loginUserData, #profileNotif{
	position: absolute;align-items: center;
	top: 25px;/*25px because parent(#loginContainer) has a 25px border radius*/
	right: 0;left: 0;background-color: var(--white);
}
/*used to remove arrows from textfield*/
#phoneNumberTxtFld::-webkit-outer-spin-button,
#phoneNumberTxtFld::-webkit-inner-spin-button {-webkit-appearance: none;margin: 0;}
/*Istikharah*/
/*slider content*/
#istSlider_light{background-color: var(--primary-light) ;}
#istSlider_dark{background-color: var(--primary-dark) ;}
/*slider media*/
/* #istSlider_light div[class^='Slide-mediaBackground']{background-color: var(--primary-light);}
#istSlider_dark div[class^='Slide-mediaBackground']{background-color: var(--primary-dark);} */
#istSlider_light div:first-child{background-color: var(--primary-light);}
#istSlider_dark div:first-child{background-color: var(--primary-dark);}
.ist_appbar_light{background-color: var(--primary-light) !important;}
.ist_appbar_dark{background-color: var(--primary-dark) !important;}
#ist_holder{
	position: absolute;
	left: 0;
	bottom: 0;
	direction: rtl;
	padding: 7% 7% 0;
	box-sizing: border-box;
	overflow-y: scroll;
}
.istStepActiveIcon_light{
	padding-inline-end: 1vw;
	color: var(--primary-light) !important;
}
.button_light{
	box-shadow: none !important;
	background-color: var(--primary-light) !important;
	color: var(--white) !important;
	margin: 5px !important;
}
.button_dark{
	box-shadow: none !important;
	background-color: var(--white) !important;
	color: var(--primary-light) !important;
	margin: 5px !important;
}
.istStepContent_rtl{
	border-right: 1px solid rgb(189, 189, 189);
	border-left: 0 !important;
	margin-right: 25px;
	margin-left: 0 !important;
	font-family: vazir;
}
.connector{
	margin-right: 25px;
	border-right: 1px solid rgb(189, 189, 189);
	height: 30px; 
	
}
div[id="marc"]{z-index: 1 !important;}

/*khatam*/
#myKhatamList,#otherKhatamList{
	background:white;
	padding:4vw;
	margin:0;
	min-height: 100%;
}

#khatamHeader{
	position: fixed !important;
	z-index: 1;
	height: 8vh;
	box-shadow: none !important;
	justify-content: space-between;
	padding: 0 1vh !important;
	border: 1px solid ;
	left:0;
	flex-direction: row-reverse !important;
}
#newKhatamDialog{width:80%;}
#newKhatamTitle{color: var(--primary-dark);}
.selectedSlicing{background-color: rgba(0, 0, 0, 0.12);}
#mainKhatamProgress,#quranProgress{position:fixed;transform: translateX(-50%);animation: none;}
.slicingNumber{
	position: relative;
	color: var(--primary-dark);
	padding: 20% 0;
	background-color: rgba(0, 0, 0, 0.05);
}
.slicingCard{
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}
.slicingPaper{
	height:100%;
}
.slicingPaper:hover{
	box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
}
#newKhDescTagBox{flex-direction: row;}
#newKhDesc{
	width:60%;max-width:60%;min-width:60%;height:auto;margin:0 2%;border: none;
	background-color: rgba(0, 0, 0, 0.05); font-family:vazir;}
.MuiTypography-root,.MuiButton-root,.MuiInput-root{font-family: vazir !important;}
.khatamListItemTitle{margin:1vh !important; }
.khatamListItemProgress{
	background-color: var(--primary-light-light) !important;
	height: 0.6vh !important;
	border-radius: 10px;
}
.khatamListItemProgress>.MuiLinearProgress-barColorPrimary{background-color: var(--primary-dark) !important;}
#khatamPagePaper{background-color: rgba(238, 238, 238, 0.5);}
#khtamaPageSwitches{
	flex-direction: row;
}
#khatamPageContent{padding: 0 10%;}

/*cd stands for open drawer*/
/*od stands for close drawer*/
/*rtl used for rtl languages*/
/*ltr used for ltr languages*/
/*light means light theme*/
/*dark means dark theme*/
/************extra small screen***************************************************************/
/****************    *********    **************************************************************/
/*****************    *******    ******************************************************************/
/******************    *****    ******************************************************************/
/*******************    ***    *********************************************************************/
/********************    *    ************************************************************************/
/*********************       ********************************************************************/
/**********************     *********************************************************************/
/**********************     ****************************************************************/
/*********************       *****************************************************************/
/********************    *    ****************************************************************/
/*******************    ***    *********************************************************/
/******************    *****    *******************************************************/
/*****************    *******    ****************************************************/
/****************    *********    ***********************************************/
/************************************************************************************/
@media only screen and (max-width: 600px){
	.appbar_od{
		left: 0;
		right: 0;
	}
	#sidebarDrawer .MuiPaper-root{width:90vw;}
	/*in small screens, search drawer fill 95% of view width*/
	#searchDrawer>.MuiPaper-root , #loginDrawer>.MuiPaper-root{width: 95Vw;}
	#searchTopBar,#searchContent{width: 95vw !important;}
	#logo{font-size: 18px !important;}
	#optionsDialog{
		max-width: 90%;
		max-height: 90%;
		min-width: 90%;
		min-height: 90%;
	}
	.optionsInputLabel{
		font-size: 1rem !important;
		margin-top: 25px;
	}
	#optionsDisplayTabContent{
		grid-row-gap: 30px;
	}
	.wrapper_od {width: 100%;}
	.quranPageHeader_cd ,.quranPageHeader_od{width: 100% !important;}
	.content_od ,.content_cd {width: 100vw;}
	#avatarImg{width:25vw;height:25vw}
	#recitebarHolder{
		right: 0;
		left: 0;
	}
	.recitebarHolder_cd,.recitebarHolder_od{width: 100vw !important;}
	#recitebar{width: 100vw;}
	footer{font-size: 13px;}
	/*Istikharah*/
	div[id="marc"] , div[id="marc"] .MuiBackdrop-root{
		top:8vh !important;
		bottom:50vh !important;
	}
	#ist_holder{
		width: 100vw;
		top: 50vh;
	}

	/*khatam*/
	.khatamSection_od{width: 66vw;}

	.khatamHeader_cd ,.khatamHeader_od{width: 100% !important;}
	#newKhatamDialog{width:96%;margin:2%}
	#khatamHeader{top: 50vh !important;}
	#khatamSection{padding-top: 50vh;height: 100vh;background: white;}
	.khatamSection_cd{width: 100vw;}
	#myKhatamList,#otherKhatamList{width:100vw !important;}
	#khatamList{padding-top: 8vh;}
	#newKhatamBackdrop{top:0;width:100vw}
	#newKhDescTagBox{flex-direction: column;}
	#newKhDesc{
		width:95%;max-width:95%;min-width:95%;height:auto;margin:0 2%;border: none;
		background-color: rgba(0, 0, 0, 0.05); font-family:vazir;}
	#mainKhatamProgress,#quranProgress{top:60vh;left:50vw;}
	.khatamListItemGrid{height: 28vh;}
	#khatamPagePaper{
		height: 30vh;
		width: 30vw;
	}
	#khatamPageContent{padding: 0 5%;}
}
/********************small screen*************************************************************/
/*********************************************************************************************/
/***********************          ************************************************************/
/*********************   *********  **********************************************************/
/********************   **********************************************************************/
/********************   **********************************************************************/
/*********************   *********************************************************************/
/***********************       ***************************************************************/
/*****************************    ************************************************************/
/********************************   **********************************************************/
/*********************************   *********************************************************/
/********************** **********   *********************************************************/
/***********************  *******   **********************************************************/
/************************         ************************************************************/
/*********************************************************************************************/
/*********************************************************************************************/
/*********************************************************************************************/

@media only screen and (min-width: 600px) and (max-width: 960px) {
	.appbar_od{
		left: 0;
		right: 0;
	}
	#sidebarDrawer .MuiPaper-root{width:90vw;}
	/*in small screens, search drawer fill 95% of view width*/
	#searchDrawer>.MuiPaper-root , #loginDrawer>.MuiPaper-root{width: 65vw;}
	#searchTopBar,#searchContent{width: 65vw !important;}
	#logo{font-size: 20px !important;}
	#optionsDialog{
		max-width: 600px;
		max-height: 600px;
		min-width: 600px;
		min-height: 600px;
	}
	.wrapper_od {width: 100%;}
	.quranPageHeader_cd ,.quranPageHeader_od{width: 100% !important;}
	.content_od ,.content_cd {width: 100vw;}
	#avatarImg{width:25vw;height:25vw}
	#drawerlogo{width: 80%;align-self: center;padding: 0 0 2vh 0;}
	#recitebarHolder{
		right: 0;
		left: 0;
	}
	.recitebarHolder_cd,.recitebarHolder_od{width: 100vw !important;}
	#recitebar{width: 100vw;}
	footer{font-size: 15px;}
	/*Istikharah*/
	div[id="marc"] , div[id="marc"] .MuiBackdrop-root{
		top:8vh !important;
		bottom:50vh !important;
	}
	#ist_holder{
		width: 100vw;
		top: 50vh;
	}
	/*khatam*/
	.khatamSection_od{width: 66vw;}
	.khatamHeader_cd ,.khatamHeader_od{width: 100% !important;}
	#newKhatamDialog{width:95%;}
	#khatamHeader{top: 50vh !important;}
	#khatamSection{padding-top: 50vh;height: 100vh;background: white;}
	.khatamSection_cd{width: 100vw;}
	#myKhatamList,#otherKhatamList{width:100vw !important;}
	#khatamList{padding-top: 8vh;}
	#mainKhatamProgress,#quranProgress{top:60vh;left:50vw;}
	.khatamListItemGrid{height: 23vh;}
	#khatamPagePaper{
		height: 22vh;
		width: 25vw;
	}
}

/********************medium screen**********************************************************/
/***************   *******************  ****************************************************/
/***************    *****************   ****************************************************/
/***************  *  ***************    ****************************************************/
/***************  **  *************  *  ****************************************************/
/***************  ***  ***********  **  ****************************************************/
/***************  ****  *********  ***  ****************************************************/
/***************  *****  *******  ****  ****************************************************/
/***************  ******  *****  *****  ****************************************************/
/***************  *******  ***  ******  ****************************************************/
/***************  ********  *  *******  ****************************************************/
/***************  *********  *********  ****************************************************/
/*************      ****************      **************************************************/
/********************medium screen**********************************************************/
@media only screen and (min-width: 960px) and (max-width: 1280px) {
	:root{
		--drawer-width:25%;
	}
	#appbar{transition: all 225ms;}
	.appbar_rtl{
		left: 0 !important;
		right: var(--drawer-width) !important;
	}
	.appbar_ltr{
		flex-direction: row-reverse !important;
		right: 0 !important;
		left: var(--drawer-width) !important;
	}
	.appbar_od{width:calc(100% - var(--drawer-width)) !important;}
	#sidebarDrawer .MuiPaper-root{width:var(--drawer-width);}


	/*in small screens, search drawer fill 95% of view width*/
	#searchDrawer>.MuiPaper-root , #loginDrawer>.MuiPaper-root{width: 45vw;}
	#searchTopBar,#searchContent{width: 65vw !important;}
	#logo{font-size: 22px !important;}
	#optionsDialog{
		max-width: 600px;
		max-height: 600px;
		min-width: 600px;
		min-height: 600px;
	}
	.wrapper_od{width: calc(100% - var(--drawer-width));}
	.quranPageHeader_cd ,.quranPageHeader_od{width: 71% !important;}
	.quranPageHeader_rtl{right: 27% !important;}
	.quranPageHeader_ltr{left: 27% !important;}
	.content_od {width: 100%;}
	.content_cd {
		width: 90vw;
		margin: 0 auto;
	}
	#avatarImg{width:25vw;height:25vw}
	/* #drawerlogo{width: 100%;align-self: center;padding: 0 0 2vh 0;} */
	#recitebarHolder{
		right: 0;
		left: 0;
	}
	.recitebarHolder_cd,.recitebarHolder_od{width: 100vw !important;}
	#recitebar{width: 100vw;}
	footer{font-size: 17px;}
	/*Istikharah*/
	div[id="marc"] , div[id="marc"] .MuiBackdrop-root{
		top:8vh !important;
		bottom:0 !important;
		width: 34vw;
		left: 66vw !important;
	}
	#ist_holder{
		width: 100vw;
		top: 50vh;
	}
	/*khatam*/
	.khatamSection_od{width: 66vw;}
	.khatamSection_cd{width: 66vw;}
	.khatamHeader_cd ,.khatamHeader_od{width: 66% !important;}
	#khatamHeader{top: 8vh !important;}
	#khatamSection{padding-top: 8vh;height: 100vh;background: white;}
	#myKhatamList,#otherKhatamList{width:66vw !important;}
	#khatamList{padding-top: 8vh;width: 66vw;}
	#newKhatamBackdrop{top:0;width:66vw}
	#mainKhatamProgress,#quranProgress{top:20vh;left:33vw;}
	.khatamListItemGrid{height: 23vh;}
	#khatamPagePaper{
		height: 22vh;
		width: 14vw;
	}
}

/********************large screen****************************************************/
/**************    ******************************************************************/
/***************   ******************************************************************/
/***************   ******************************************************************/
/***************   ******************************************************************/
/***************   ******************************************************************/
/***************   ******************************************************************/
/***************   ******************************************************************/
/***************   ******************************************************************/
/***************   ******************************************************************/
/***************   ******************************************************************/
/***************   ************** ***************************************************/
/***************   ************** ***************************************************/
/*************                    ***************************************************/
/*************                    ***************************************************/
/************************************************************************************/
@media only screen and (min-width: 1280px) and (max-width: 1920px) {
	:root{
		--drawer-width:25%;
	}
	#appbar{transition: all 225ms;}
	.appbar_rtl{
		left: 0 !important;
		right: var(--drawer-width) !important;
	}
	.appbar_ltr{
		flex-direction: row-reverse !important;
		right: 0 !important;
		left: var(--drawer-width) !important;
	}
	.appbar_od{width:calc(100% - var(--drawer-width)) !important;}
	#sidebarDrawer .MuiPaper-root{width:var(--drawer-width);}
	/*in small screens, search drawer fill 95% of view width*/
	#searchDrawer>.MuiPaper-root , #loginDrawer>.MuiPaper-root{width: 40vw;}
	#searchTopBar,#searchContent{width: 40vw !important;}
	#logo{font-size: 24px !important;}
	#optionsDialog{
		max-width: 600px;
		max-height: 600px;
		min-width: 600px;
		min-height: 600px;
	}
	#wrapper{
		background-image: url('/img/back.jpg');
		transition: all 225ms;
	}
	.wrapper_od{width: calc(100% - var(--drawer-width));}
	.wrapper_ltr{margin-left: var(--drawer-width);}
	.wrapper_rtl{margin-right: var(--drawer-width);}
	#quranPageHeader{transition: all 225ms;}
	.quranPageHeader_rtl{right: 27% !important;}
	.quranPageHeader_ltr{left: 27% !important;}
	.quranPageHeader_cd {
		width: 76% !important;
		right: 12% !important;
		left: 12% !important;
	}
	.quranPageHeader_od{width: 71% !important;}
	#content{transition: all 225ms;}
	.content_od {width: 100%;}
	.content_cd {
		width: 90vw;
		margin: 0 auto;
	}
	#khatamHeader{top: 8vh !important;}
	#newKhatamBackdrop{top:0;width:66vw}
	#khatamSection{padding-top: 8vh;height: 100vh;background: white;}
	.khatamSection_cd{width: 66vw;}
	#avatarImg{width:13vw;height:13vw}
	#recitebarHolder{transition: all 225ms;}
	.recitebarHolder_cd{
		width: 90vw !important;
		margin: auto;
	}
	.recitebarHolder_od{width: calc(100% - var(--drawer-width)) !important;}
	.recitebarHolder_rtl{margin-right:var(--drawer-width) !important;}
	.recitebarHolder_ltr{margin-left: var(--drawer-width) !important;}
	footer{font-size: 19px;}
	.footer_od{width:  calc(100% - var(--drawer-width)) !important;}
	.footer_rtl{margin-right:var(--drawer-width) !important;}
	.footer_ltr{margin-left: var(--drawer-width) !important;}
	/*Istikharah*/
	.ist_appbar{
		width: 34vw !important;
		left: 66vw !important;
	}
	div[id="marc"] , div[id="marc"] .MuiBackdrop-root{
		top:8vh !important;
		bottom:0 !important;
		width: 34vw;
		left: 66vw !important;
	}
	#ist_holder{
		width: 66vw;
		top: 0;
	}

	/*khatam*/
	#khatamSection{transition: all 225ms;height: 100vh;background: white;}
	#myKhatamList,#otherKhatamList{width:66vw !important;}
	#khatamList{padding-top: 8vh;width: 66vw;}
	.khatamSection_od{width: calc(100% - var(--drawer-width));}
	.khatamSection_cd{width: 66vw;}
	#khatamHeader{transition: all 225ms;}
	.khatamHeader_cd ,.khatamHeader_od{width: 66vw !important;}
	#mainKhatamProgress,#quranProgress{top:20vh;left:33vw;}
	.khatamListItemGrid{height: 23vh;}
	#khatamPagePaper{
		height: 22vh;
		width: 14vw;
	}
}

/********************Xlarge screen****************************************************/
/****************    *********    *****    ******************************************************************/
/*****************    *******    *** ***   ******************************************************************/
/******************    *****    ********   ******************************************************************/
/*******************    ***    *********   ******************************************************************/
/********************    *    **********   ******************************************************************/
/*********************       ***********   ******************************************************************/
/**********************     ************   ******************************************************************/
/**********************     ************   ******************************************************************/
/*********************       ***********   ******************************************************************/
/********************    *    **********   ******************************************************************/
/*******************    ***    *********   ************** ***************************************************/
/******************    *****    ********   ************** ***************************************************/
/*****************    *******    *****                    ***************************************************/
/****************    *********    ****                    ***************************************************/
/************************************************************************************/
/********************extra large screen**********************/
@media only screen and (min-width: 1920px) {
	:root{
		--drawer-width:25%;
	}
	#appbar{transition: all 225ms;}
	.appbar_rtl{
		left: 0 !important;
		right: var(--drawer-width) !important;
	}
	.appbar_ltr{
		flex-direction: row-reverse !important;
		right: 0 !important;
		left: var(--drawer-width) !important;
	}
	.appbar_od{width:calc(100% - var(--drawer-width)) !important;}
	#sidebarDrawer .MuiPaper-root{width:var(--drawer-width);}
	/*in xl screens, search drawer fill 45% of view width*/
	#searchDrawer>.MuiPaper-root , #loginDrawer>.MuiPaper-root{width: 40vw;}
	#searchTopBar,#searchContent{width: 40vw !important;}
	#logo{font-size: 24px !important;}
	#optionsDialog{
		max-width: 600px;
		max-height: 700px;
		min-width: 600px;
		min-height: 600px;
	}
	#wrapper{
		background-image: url('/img/back.jpg');
		transition: all 225ms;
	}
	.wrapper_od{width: calc(100% - var(--drawer-width));}
	.wrapper_ltr{margin-left: var(--drawer-width);}
	.wrapper_rtl{margin-right: var(--drawer-width);}
	#quranPageHeader{transition: all 225ms;}
	.quranPageHeader_rtl{right: 27% !important;}
	.quranPageHeader_ltr{left: 27% !important;}
	.quranPageHeader_cd {
		width: 76% !important;
		right: 12% !important;
		left: 12% !important;
	}
	.quranPageHeader_od{width: 71% !important;}
	#content{transition: all 225ms;}
	.content_od {width: 100%;}
	.content_cd {
		width: 80vw;
		margin: 0 auto;
	}
	#avatarImg{width:13vw;height:13vw}
	#recitebarHolder{transition: all 225ms;}
	.recitebarHolder_cd{
		width: 80vw !important;
		margin: auto;
	}
	.recitebarHolder_od{width: calc(100% - var(--drawer-width)) !important;}
	.recitebarHolder_rtl{margin-right:var(--drawer-width) !important;}
	.recitebarHolder_ltr{margin-left: var(--drawer-width) !important;}
	footer{font-size: 19px;}
	.footer_od{width: calc(100% - var(--drawer-width)) !important;}
	.footer_rtl{margin-right:var(--drawer-width) !important;}
	.footer_ltr{margin-left: var(--drawer-width) !important;}
	/*Istikharah*/
	/*slider media*/
	.ist_appbar{
		width: 34vw !important;
		left: 66vw !important;
	}
	div[id="marc"] , div[id="marc"] .MuiBackdrop-root{
		top:8vh !important;
		bottom:0 !important;
		width: 34vw;
		left: 66vw !important;
	}
	#ist_holder{
		width: 66vw;
		top: 0;
	}

	/*khatam*/
	#khatamSection{padding-top: 8vh;transition: all 225ms;height: 100vh;background: white;}
	.khatamSection_od{width: calc(100% - var(--drawer-width));}
	.khatamSection_cd{width: 66vw;}
	#khatamHeader{transition: all 225ms;}
	.khatamHeader_cd ,.khatamHeader_od{width: 66vw !important;}
	#khatamHeader{top: 8vh !important;}
	#newKhatamBackdrop{top:0;width:66vw}
	#myKhatamList,#otherKhatamList{width:66vw !important;}
	#khatamList{padding-top: 8vh;width: 66vw;}
	#mainKhatamProgress,#quranProgress{top:20vh;left:33vw;}
	.khatamListItemGrid{height: 15vh;}
	#khatamPagePaper{
		height: 20vh;
		width: 14vw;
	}
}
/****************************************************************************/
/****************************************************************************/
/****************************************************************************/
/****************************************************************************/
/****************************************************************************/
/****************************************************************************/
/****************************************************************************/
/****************************************************************************/
/****************************************************************************/
/****************************************************************************/
/****************************************************************************/
/****************************************************************************/
/****************************************************************************/
/****************************************************************************/
.appbar_cd {
	left: 0 !important;
	right: 0 !important;
}















.aya{
	word-spacing: 5px;
	font-weight: normal;
	text-align: justify !important;
	direction: rtl;
	padding: 10px 4px 0px;
	border-radius: 0;
	outline: none;
}
.aya-active{
	outline: none;
	background-color: rgba(175, 175, 175, 0.1);
}

.word{
	padding: 5px 3px;
	display: inline-block;
}
.word:hover{
	/* color: #fff; */
	color: #00BCD4;
	cursor: pointer;
}
.word span{
	color: #FF9800;
}

.sura-name{
	text-align: center !important;
	line-height: 0;
	/* font-weight: normal;
	direction: rtl;
    padding: 18px 0px 10px;
    margin-top: 7px;
    margin-bottom: 12px; */
}
.sura-name img{
	width: 150px;
}
.bismillah{
	text-align: center !important;
	font-family: 'neyrizi';
	font-size: 20px;
	font-weight: normal;
	line-height: 55px;
	direction: rtl;
}

.ayaNumber{
	text-decoration: none;
	font-size: 18px;
	font-weight: normal;
	direction: rtl;
	white-space: nowrap;
	cursor: pointer;
	outline: none;
	font-family: serif;
}
/* Others */
.DividerStyle{
	margin: 30px 0px !important;
}


.recitbar_progress{
	position: absolute;
	top: 0;
	width: 100%;
}

.page_number_style{
	font-family: Roboto;
  vertical-align: text-bottom;
  line-height: 30px;
  color: var(--black);
}
.display_option_right{
	float: right;
}
.display_option_left{
	float: left;
}
.font_color_box{
	margin-right: 10px;
	margin-top: 40px;
}

.dir_rtl{
	direction: rtl !important;
	
}

/* Istikharah */


.searchBox:focus{
	outline: none;
}
